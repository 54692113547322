import React, { useState } from "react"
import PropTypes from "prop-types"

import { graphql } from "gatsby"

import makeStyles from "@material-ui/core/styles/makeStyles"

import CallRoundedIcon from "@material-ui/icons/CallRounded"

import Grid from "@material-ui/core/Grid"
import Hidden from "@material-ui/core/Hidden"
import Container from "@material-ui/core/Container"

import SEO from "../components/SEO"
import Breadcrumbs from "../components/Breadcrumbs"
import ContactLocationCard from "../components/cards/ContactLocationCard"
import SectionTitle from "../components/SectionTitle"
import GeneralContact from "../components/contacts/GeneralContact"
import HelpersGallery from "../components/carousels/HelpersGallery"
import ContactPopup from "../components/contacts/ContactPopup"

const useStyles = makeStyles(theme => ({
  contact: {
    position: "sticky",
    top: 90,
  },

  wideContact: {
    [theme.breakpoints.down("sm")]: {
      marginLeft: -theme.spacing(3),
      width: "100vw",
    },
    [theme.breakpoints.down("xs")]: {
      marginLeft: -theme.spacing(2),
      width: "100vw",
    },
  },
}))

const ContactPage = ({
  data: { contactPage, helpers },
  pageContext: { locale, breadcrumbs, slug },
  location,
}) => {
  const classes = useStyles()

  const [selectedHelperUid, setSelectedHelperUid] = useState("-")
  const [selectedContactLocationUid, setSelectedContactLocationUid] = useState(
    "-"
  )
  const [contactModalOpen, setContactModalOpen] = useState(false)

  const schema = contactPage.data.schema_json.text || ""
  const ogp = contactPage.data.ogp_tags || ""

  const meta = {
    title: contactPage.data.meta_title,
    description: contactPage.data.meta_description,
    keywords: contactPage.data.meta_keywords,
    author: "Medicina Interventionala",
  }

  const locations = contactPage.data.locations.map(
    l => l.location_link.document
  ).filter(l => l)

  const helperList = helpers.edges.map(r => r.node)

  const onSelectHelper = helperUid => {
    setSelectedHelperUid(helperUid)
    setContactModalOpen(true)
  }

  const onSelectContactLocation = contactLocationUid => {
    setSelectedContactLocationUid(contactLocationUid)
    setContactModalOpen(true)
  }

  return (
    <>
      <SEO
        schema={schema}
        ogp={ogp}
        meta={meta}
        locale={locale}
        breadcrumbs={breadcrumbs}
      />
      <main>
        <Container maxWidth="lg">
          <Breadcrumbs breadcrumbs={breadcrumbs} />

          <br />
          <Grid container spacing={2}>
            <Grid item xs={12} md={8}>
              <SectionTitle component="h1">
                <span style={{ fontWeight: 700 }}>Unde ne găsești</span>
              </SectionTitle>
              <br />
              <Grid container direction="row" spacing={2}>
                {locations.map(contactLocation => (
                  <Grid item xs={6} key={contactLocation.uid}>
                    <ContactLocationCard
                      location={contactLocation}
                      onSelect={onSelectContactLocation}
                    />
                  </Grid>
                ))}
              </Grid>
            </Grid>

            <Grid item xs={12} md={4}>
              <Hidden smDown implementation="css">
                <SectionTitle
                  component="div"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexWrap: "wrap",
                  }}
                >
                  <CallRoundedIcon />
                  &nbsp;Call Center&nbsp;
                  <span style={{ fontWeight: 700 }}>031 9300</span>
                </SectionTitle>
                <br />
                <div className={classes.contact}>
                  <GeneralContact location={location} locale={locale} spread />
                </div>
              </Hidden>
            </Grid>

            <Grid item xs={12}>
              <br />
              <SectionTitle component="h2">
                <span style={{ fontWeight: 700 }}>MONZA ARES Helper</span>
              </SectionTitle>
              <br />
              <HelpersGallery helpers={helperList} onSelect={onSelectHelper} />
            </Grid>
            <Grid item xs={12}>
              <div className={classes.wideContact}>
                <GeneralContact location={location} locale={locale} />
              </div>
            </Grid>
          </Grid>
        </Container>
        <ContactPopup
          locale={locale}
          location={location}
          open={contactModalOpen}
          handleClose={() => setContactModalOpen(false)}
          selectedContactLocationUid={selectedContactLocationUid}
          selectedHelperUid={selectedHelperUid}
        />
      </main>
    </>
  )
}

export default ContactPage

export const query = graphql`
  query ContactPageQuery($slug: String!, $locale: String!) {
    settings: prismicSettings(lang: { eq: $locale }) {
      data {
        site_title
      }
    }

    contactPage: prismicContact(uid: { eq: $slug }, lang: { eq: $locale }) {
      id
      data {
        call_center_contact
        locations {
          location_link {
            document {
              ... on PrismicContactLocation {
                uid
                lang
                data {
                  address {
                    html
                  }
                  coordinates {
                    latitude
                    longitude
                  }
                  title {
                    text
                  }
                  city {
                    text
                  }
                  long_title {
                    text
                  }
                  gallery {
                    image {
                      alt
                      gatsbyImageData(layout: FULL_WIDTH)
                    }
                  }
                }
              }
            }
          }
        }
        title {
          text
        }
        schema_json {
          text
        }
        ogp_tags {
          content
          property
        }
        meta_title
        meta_description
        meta_keywords
      }
    }

    helpers: allPrismicHelper(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          lang
          uid
          data {
            name {
              text
            }
            description {
              html
            }
            ratings {
              field
              value
            }
            specialisations {
              specialisation: specialization {
                document {
                  ... on PrismicSpecialisation {
                    id
                    uid
                    data {
                      title {
                        text
                      }
                    }
                  }
                }
              }
            }
            profile_image {
              alt
              gatsbyImageData(layout: FULL_WIDTH, aspectRatio: 1.5)
            }
          }
        }
      }
    }
  }
`

ContactPage.propTypes = {
  data: PropTypes.shape({
    settings: PropTypes.object.isRequired,
    contactPage: PropTypes.object.isRequired,
    helpers: PropTypes.object.isRequired,
  }).isRequired,
  pageContext: PropTypes.shape({
    locale: PropTypes.string.isRequired,
    breadcrumbs: PropTypes.array.isRequired,
  }).isRequired,
  location: PropTypes.object.isRequired,
}
